import { useState,useEffect } from 'react'
import './App.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Web3 from 'web3'
import { TOKEN_ABI,TOKEN_ADDRESS } from './tokenData'
function App() {
  const [amount,setAmount] = useState(0)
  const [txHash,setTxHash] = useState('')
  const [price,setPrice] = useState()
  const [connected,setConnected] = useState(false)
  const connectWallet = () =>{
    window.ethereum.enable()
    setConnected(true)
  }
  const buyToken = async () =>{
    const web3 = new Web3(window.ethereum)
    const accounts = await  web3.eth.getAccounts()
    const contractInstance = new web3.eth.Contract(TOKEN_ABI,TOKEN_ADDRESS)
    const receipt = await contractInstance.methods.buyToken().send({from:accounts[0],value:amount*price})
    setTxHash(`https://etherscan.io/tx/${receipt.transactionHash}`)
    console.log(receipt)
  }
  useEffect(()=>{
    async function getPrice(){
      const web3 = new Web3(window.ethereum)
      const contractInstance = new web3.eth.Contract(TOKEN_ABI,TOKEN_ADDRESS)
      const tokenPrice = await contractInstance.methods.tokenPrice.call().call()
      setPrice(tokenPrice)
    }
    getPrice()
  },[])
  return (
    <div className="App">
      <div className="connect-wallet">
      {(connected)?(
      <span style={{position:'fixed',right:5,top:5}}>Connected</span>
      ):(
      <Button style={{position:'fixed',right:5,top:5}} variant="contained" color="primary" onClick={connectWallet}>Connect Wallet</Button>
      )}
      
      </div>
      <div className="saleForm">
      <b>Only supports W3 wallets <br /></b>
	  <small> Maximum order size: 10 ETH</small>
      <p>Price: 1 $iCube = {price/1e18} ETH</p>
      <TextField id="outlined-basic" label="iCube Amount" variant="outlined" onChange={(e)=>{
        setAmount(e.target.value)
      }}/>
      <sub> <br />={(amount*price)/1e18} ETH</sub>
	  <br />
      <Button style={{marginTop:'5px'}} variant="contained" color="primary" onClick={buyToken}>Buy</Button> 
	  <br /><small> **See FAQs for more buying options</small>
      </div>
      <div>
        {txHash}
      </div>
    </div>
  );
}

export default App;
